import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = [ 'filter', 'items', 'nav' ]

  filter(e) {
    const url = new URL(e.target.href)
    if (url.href === this.url) url.searchParams.delete('tag')
    this.call(
      url.href,
      () => {
        this.itemsTarget.classList.add('loader')
      },
      (responseDoc) => {
        this.itemsTarget.innerHTML = responseDoc.querySelector('[data-target~="pagination.items"]').innerHTML
      },
      () => {
        this.filterTargets.map((filter) => filter.classList.remove(...filter.dataset.active.split(' ')))
        if ( url.searchParams.has('tag') ) e.target.classList.add(...e.target.dataset.active.split(' '))
        this.itemsTarget.classList.remove('loader')
        this.url = url.href
      }
    )
    e.preventDefault()
  }

  add(e) {
    this.call(
      e.target.href,
      () => {
        this.navTarget.classList.add('loader')
      },
      (responseDoc) => {
        this.itemsTarget.append(...responseDoc.querySelector('[data-target~="pagination.items"]').childNodes)
      },
      () => {
        this.navTarget.classList.remove('loader')
      },
    )
    e.preventDefault()
  }

  call(url, before, success, after) {
    before()
    axios.get(url)
      .then((response) => {
        const responseDoc = document.createElement('html')
        responseDoc.innerHTML = response.data
        this.navTarget.innerHTML = responseDoc.querySelector('[data-target~="pagination.nav"]').innerHTML
        success(responseDoc)
      })
      .catch((error) => {
        console.log(error)
        this.itemsTarget.innerHTML = '<p class="text-center">An error occurred, please refresh the page</p>'
      }).then(() => {
        this.navTarget.classList.remove('loader')
        after()
      })
  }
}
