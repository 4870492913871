import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'drawer' ]

  toggle(e) {
    if ( window.getComputedStyle(this.drawerTarget).display === 'none' ) {
      this.slideDown(this.drawerTarget, 400)
      e.target.classList.add('is-open')
    } else {
      this.slideUp(this.drawerTarget, 400)
      e.target.classList.remove('is-open')
    }
  }

  slideUp(target, duration) {
    target.style.transitionProperty = 'height, margin, padding, opacity'
    target.style.transitionDuration = duration + 'ms'
    target.style.height = target.offsetHeight + 'px'
    target.offsetHeight
    target.style.overflow = 'hidden'
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0
    target.style.opacity = 0
    window.setTimeout( () => {
      target.style.display = 'none'
      target.style.removeProperty('height')
      target.style.removeProperty('padding-top')
      target.style.removeProperty('padding-bottom')
      target.style.removeProperty('margin-top')
      target.style.removeProperty('margin-bottom')
      target.style.removeProperty('opacity')
      target.style.removeProperty('overflow')
      target.style.removeProperty('transition-duration')
      target.style.removeProperty('transition-property')
    }, duration)
  }

  slideDown(target, duration) {
    target.style.removeProperty('display')
    let display = window.getComputedStyle(target).display
    if (display === 'none') display = 'block'
    target.style.display = display
    let height = target.offsetHeight
    target.style.overflow = 'hidden'
    target.style.height = 0
    target.style.paddingTop = 0
    target.style.paddingBottom = 0
    target.style.marginTop = 0
    target.style.marginBottom = 0
    target.style.opacity = 0
    target.offsetHeight
    target.style.transitionProperty = "height, margin, padding, opacity"
    target.style.transitionDuration = duration + 'ms'
    target.style.height = height + 'px'
    target.style.removeProperty('padding-top')
    target.style.removeProperty('padding-bottom')
    target.style.removeProperty('margin-top')
    target.style.removeProperty('margin-bottom')
    target.style.removeProperty('opacity')
    window.setTimeout( () => {
      target.style.removeProperty('height')
      target.style.removeProperty('overflow')
      target.style.removeProperty('transition-duration')
      target.style.removeProperty('transition-property')
    }, duration)
  }
}
