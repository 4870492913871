import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['item']

    toggleModal() {
        this.itemTarget.classList.toggle('opacity-0')
        this.itemTarget.classList.toggle('pointer-events-none')
    }
}
