import { Controller } from 'stimulus'
import { tns } from 'tiny-slider/src/tiny-slider'
import { theme } from '../config/tailwind.config'

export default class extends Controller {

  initialize() {
    const items = JSON.parse(this.element.dataset.items)
    const responsive = Object.keys(items).reduce((result, screenSize) => {
      const breakpoint = parseInt(theme.screens[screenSize])
      result[breakpoint] = items[screenSize]
      return result
    }, {})
    const slider = tns({
      container: this.element,
      controls: false,
      gutter: parseFloat(theme.container.padding) * 16,
      items: 1,
      loop: false,
      mouseDrag: true,
      navPosition: 'bottom',
      responsive: responsive,
    })
  }
}
