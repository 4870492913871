import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'name', 'placeholder' ]

  initialize() {
    for (const event of ['dragover', 'dragenter']) {
      this.element.addEventListener(event, (e) => {
        e.preventDefault()
        this.element.classList.add('is-dragover')
      })
    }

    for (const event of ['dragleave', 'dragend', 'drop']) {
      this.element.addEventListener(event, (e) => {
        e.preventDefault()
        this.element.classList.remove('is-dragover')
      })
    }

    this.element.addEventListener('drop', (e) => {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(e.dataTransfer.files[0])
      this.inputTarget.files = dataTransfer.files
      this.inputTarget.dispatchEvent(new Event('change'))
    })
  }

  select() {
    const file = this.inputTarget.files[0]
    if ( file ) {
      this.nameTarget.textContent = file.name
      this.placeholderTarget.style.display = 'none'
      this.element.classList.add('is-selected')
    } else {
      this.nameTarget.textContent = ''
      this.placeholderTarget.style.removeProperty('display')
      this.element.classList.remove('is-selected')
    }
  }
}
