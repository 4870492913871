module.exports = {
  future: {},
  purge: {
    enabled: ['production', 'staging'].includes(process.env.RAILS_ENV),
    mode: 'all',
    content: [
      './app/builders/**/*.rb',
      './app/frontend/controllers/**/*.js',
      './app/helpers/**/*.rb',
      './app/views/**/*.slim',
    ],
    options: {
      whitelistPatterns: [/^data-/],
    },
  },
  theme: {
    screens: {
      sm: '420px',
      md: '768px',
      lg: '1280px',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000',
      white: '#fff',
      gray: {
        100: '#f8f2ee',
        200: '#e9d5c7',
        300: '#d6ad90',
      },
      red: {
        300: '#e76e6b',
        500: '#de3d3d',
      },
      yellow: {
        500: '#e6b74b',
        800: '#9f7744',
      },
      green: {
        500: '#90a194',
      },
      blue: {
        200: '#d5d3df',
        500: '#435b7d',
        800: '#2b245d',
      },
    },
    container: {
      center: true,
      padding: '1.5rem',
    },
    fontFamily: {
      sans: [
        'Nunito',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      secondary: ['Amaranth', 'sans-serif'],
      tertiary: ['Coves', 'sans-serif'],
    },
    fontSize: {
      xs: ['0.75rem', '1.2'], // 12px
      sm: ['0.875rem', '1.4'], // 14px
      base: ['1rem', '1.4'], // 16px
      lg: ['1.125rem', '1.33333'], // 18px
      xl: ['1.25rem', '1.33333'], // 20px
      '2xl': ['1.5rem', '1.33333'], // 24px
      '3xl': ['1.875rem', '1.2'], // 30px
      '4xl': ['2.125rem', '1.2'], // 34px
    },
    letterSpacing: {
      normal: '0',
      medium: '.125em',
      wide: '.25em',
    },
    extend: {
      borderColor: (theme) => ({
        default: theme('colors.blue.200'),
      }),
      boxShadow: (theme) => ({
        error: `0 0 0 3px ${theme('colors.red.300')}`,
      }),
      gridTemplateColumns: {
        'expanded-center': 'auto 1fr auto',
      },
      height: {
        80: '20rem', // 320px
        96: '24rem', // 384px
        128: '32rem', // 512px
        192: '48rem', // 768px
      },
      inset: {
        2: '0.5rem', // 8px
      },
      width: {
        80: '20rem', // 320px
        96: '24rem', // 384px
      },
      minWidth: {
        4: '1rem', // 16px
        48: '12rem', // 192px
      },
      maxWidth: (theme) => ({
        'half-screen-lg': `calc(${theme('screens.lg')} / 2)`,
      }),
      zIndex: {
        '-10': '-10',
      },
    },
    typography: (theme) => ({
      default: {
        css: {
          color: theme('colors.black'),
          lineHeight: 1.5,
          a: {
            color: theme('colors.blue.500'),
          },
          h2: {
            fontSize: theme('fontSize.3xl')[0],
            lineHeight: theme('fontSize.3xl')[1],
            fontWeight: theme('fontWeight.extrabold'),
            color: theme('colors.blue.500'),
            marginBottom: '0.8em',
          },
        }
      },
      sm: {
        css: {
          p: {
            marginTop: '0.2em',
            marginBottom: '0.2em',
          },
        }
      }
    }),
  },
  variants: {
    borderWidth: ['responsive', 'first', 'hover', 'focus'],
  },
  plugins: [
    require('@tailwindcss/typography'),
  ],
}
