import { Controller } from 'stimulus'
import Pikaday from 'pikaday'

export default class extends Controller {
  static targets = [ 'field', 'trigger' ]

  connect() {
    this.picker = new Pikaday({
      field: this.hasFieldTarget ? this.fieldTarget : this.element,
      trigger: this.hasTriggerTarget ? this.triggerTarget : false,
      firstDay: 1,
      defaultDate: new Date(),
      setDefaultDate: true,
      i18n: {
        previousMonth : 'Mois précédent',
        nextMonth     : 'Mois suivant',
        months        : ['Janvier','Février','Mars','Avri','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
        weekdays      : ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
        weekdaysShort : ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam']
      },
    })
  }

  disconnect() {
    this.picker.destroy()
  }
}
