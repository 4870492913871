import { Controller } from 'stimulus'

export default class extends Controller {

  toTop(e) {
    e.preventDefault()
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }
}
