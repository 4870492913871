import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'panel' ]

  toggle(e) {
    e.target.classList.toggle('is-open')
    this.panelTarget.classList.toggle('is-open')
    this.element.classList.toggle('overflow-hidden')
  }
}
