import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'checkbox' ]

  validate() {
    const limit = this.element.dataset.limit
    if (this.checkboxTargets.filter(checkbox => checkbox.checked).length >= limit) {
      this.checkboxTargets.filter(checkbox => !checkbox.checked).map(checkbox => checkbox.setAttribute('disabled', true))
      this.scroll()
    } else {
      this.checkboxTargets.forEach(checkbox => {
        if (checkbox.dataset.disabled === 'false') {
          checkbox.removeAttribute('disabled')
        }
      })
    }
  }

  scroll() {
    const section = this.element.closest('.section')
    window.scrollTo({
      top: section.offsetTop + section.offsetHeight - window.innerHeight,
      behavior: 'smooth'
    })
  }
}
