import { Controller } from 'stimulus'
import Calendar from 'tui-calendar'
import { DateTime } from 'luxon'
import { theme } from '../config/tailwind.config'

export default class extends Controller {
  static targets = [ 'calendar' ]

  connect() {
    this.schedules = JSON.parse(this.element.dataset.planningSchedules)
    this.endOfTheDay = Math.max(21, ...this.schedules.map(schedule => DateTime.fromISO(schedule.end).setZone('Europe/Paris').plus({ hours: 1 }).toFormat('H')))
    this.calendar = new Calendar(this.calendarTarget, {
      defaultView: 'day',
      taskView: false,
      isReadOnly: true,
      usageStatistics: false,
      scheduleView: ['time'],
      week: {
        hourStart: 8,
        hourEnd: this.endOfTheDay,
        daynames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      },
      theme: this.theme,
      template: {
        weekDayname: (model) => {
          return `<div class="text-xl font-bold mt-2">${model.dayName} ${DateTime.fromJSDate(new Date(model.renderDate)).setLocale("fr").toFormat('d LLLL yyyy')}</div>`
        },
        timegridDisplayPrimaryTime: (time) => {
          return time.hour
        },
        timegridCurrentTime: () => {
          return null
        },
        time: (schedule) => {
          const startTime = DateTime.fromJSDate(schedule.start.toDate())
          const endTime = DateTime.fromJSDate(schedule.end.toDate())
          return `
            <div>
              <span class="text-base md:text-lg">${schedule.title}</span>
              <span class="text-sm text-blue-200 font-semibold hidden md:inline">de ${startTime.toFormat('HH:mm')} à ${endTime.toFormat('HH:mm')}</span>
            </div>
            <div class="text-sm text-blue-200">${schedule.body}</div>
            <div class="font-semibold text-sm text-blue-200 italic mt-1">${schedule.state}</div>
          `
        },
      },
      calendars: [
        {
          id: 'default',
          color: theme.colors.white,
          bgColor: theme.colors.blue[800],
          borderColor: 'transparent'
        },
        {
          id: 'spa',
          color: theme.colors.white,
          bgColor: theme.colors.blue[800],
          borderColor: 'transparent'
        },
        {
          id: 'sport',
          color: theme.colors.white,
          bgColor: theme.colors.blue[800],
          borderColor: 'transparent'
        },
        {
          id: 'balneo',
          color: theme.colors.white,
          bgColor: theme.colors.blue[800],
          borderColor: 'transparent'
        },
        {
          id: 'health',
          color: theme.colors.white,
          bgColor: theme.colors.blue[800],
          borderColor: 'transparent'
        },
      ],
      timezone: {
        zones: [{timezoneName: 'Europe/Paris'}]
      }
    })
    this.calendar.createSchedules(this.schedules)
    this.calendar.on('clickSchedule', e => {
      const modalEvent = new Event('modal')
      modalEvent.modalId = `modal-${e.schedule.id}`
      document.dispatchEvent(modalEvent)
    })
    this.calendar.setDate(new Date(this.element.dataset.planningDate))
  }

  disconnect() {
    this.calendar.destroy()
  }

  theme = {
    'common.border': '0',
    'common.backgroundColor': 'transparent',
    'common.holiday.color': theme.colors.blue[800],
    'common.saturday.color': theme.colors.blue[800],
    'common.dayname.color': theme.colors.blue[800],
    'common.today.color': theme.colors.blue[800],

    // week header 'dayname'
    'week.dayname.backgroundColor': theme.colors.gray[100],
    'week.today.color': theme.colors.blue[800],
    'week.pastDay.color': theme.colors.blue[200],

    // week daygrid 'daygrid'

    'week.daygridLeft.width': '80px',

    'week.today.backgroundColor': 'transparent',
    'week.weekend.backgroundColor': 'transparent',

    // week timegrid 'timegrid'
    'week.timegridLeft.width': '48px',
    'week.timegridLeft.backgroundColor': 'transparent',
    'week.timegridLeft.borderRight': '1px solid #e5e5e5',
    'week.timegridLeft.fontSize': '11px',
    'week.timegridLeftTimezoneLabel.height': '40px',

    'week.timegridOneHour.height': '120px',
    'week.timegridHalfHour.height': '60px',
    'week.timegridHalfHour.borderBottom': 'none',
    'week.timegridHorizontalLine.borderBottom': '1px solid rgba(0, 0, 0, .1)',

    'week.timegrid.paddingRight': '0',
    'week.timegrid.borderRight': '0',
    'week.timegridSchedule.borderRadius': '12px',
    'week.timegridSchedule.paddingLeft': '0',

    'week.currentTime.color': theme.colors.blue[500],
    'week.currentTime.fontSize': '10px',
    'week.currentTime.fontWeight': '600',

    'week.pastTime.color': theme.colors.blue[500],
    'week.pastTime.fontWeight': '600',

    'week.futureTime.color': theme.colors.blue[500],
    'week.futureTime.fontWeight': '600',

    'week.currentTimeLinePast.border': '0',
    'week.currentTimeLineBullet.backgroundColor': 'transparent',
    'week.currentTimeLineToday.border': '0',
    'week.currentTimeLineFuture.border': '0',
  }
}
