import { Controller } from 'stimulus'

export default class extends Controller {

  close() {
    this.element.classList.add('-translate-y-full')
    this.element.addEventListener('transitionend', () => {
      this.element.remove()
    })
  }
}
