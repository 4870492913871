require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);
require.context('../icons', true, /\.svg$/i);

require('@rails/ujs').start()
import Turbolinks from 'turbolinks';
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from '../controllers/application_controller'

Turbolinks.start();

const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, { consumer, controller, debug: false })
