import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal' ]

  connect() {
    document.addEventListener('modal', e => this.open(e.modalId))
  }

  disconnect() {
    document.removeEventListener('modal', e => this.open(e.modalId))
  }

  open(modalId) {
    this.modalTargets.map(modal => modal.classList.add('hidden'))
    this.currentModal = this.modalTargets.find(modal => modal.id === modalId)
    if ( this.currentModal ) this.currentModal.classList.remove('hidden')
    this.element.classList.remove('invisible', 'opacity-0')
  }

  close() {
    this.element.classList.add('invisible', 'opacity-0')
  }

  outside(e) {
    if ( e.target.contains(this.modalTarget) ) {
      this.close()
    }
  }
}
