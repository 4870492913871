import { Controller } from 'stimulus'
import { tns } from 'tiny-slider/src/tiny-slider'

export default class extends Controller {
  static targets = [ 'caption', 'container', 'control', 'position' ]

  initialize() {
    const slider = tns({
      container: this.containerTarget,
      controlsContainer: this.controlTarget,
      nav: false,
      mouseDrag: true,
      mode: 'gallery',
    })

    this.showInfo(slider.getInfo())

    slider.events.on('transitionEnd', (info) => {
      this.showInfo(info)
    })
  }

  showInfo(info) {
    const imageIndex = info.displayIndex
    const image = info.slideItems[imageIndex]
    this.positionTarget.textContent = `${imageIndex}/${info.slideCount}`
    this.captionTarget.textContent = image.getAttribute('alt')
  }
}
